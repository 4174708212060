import Vue from 'vue'
import Router from 'vue-router'
import Home from './pages/Home.vue'
import Models from './pages/Models.vue'
import Model from './pages/Model.vue'
import Benchmark from './pages/Benchmark.vue'
import Search from './pages/Search.vue'
import UserBench from './pages/UserBench.vue'
import PageNotFound from './pages/PageNotFound.vue'

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/', component: Home , name: 'Home' },
        { path: '/models', component: Models, name: 'Models' },
        { path: '/model', component: Model, name: 'Model' },
        { path: '/benchmark', component: Benchmark, name: 'Benchmark'},
        { path: '/search', component: Search, name: 'Search'},
        { path: '/userbench', component: UserBench, name: 'UserBench' },
        { path: '*', component: PageNotFound, name: 'PageNotFound' }
    ]
})
