<template>
    <!-- avg bench -->
    <div id="benchavg">
        <h2 class="page-header">Best models</h2>
        <ul class="list-group card">
            <li v-for="(bench, index) in bench_list" :key="index" class="list-group-item row borderless-card">
                <ModelScore :bench="bench" :index="index"/>
            </li>
        </ul>
    </div>
</template>

<script>
    import { API_CONFIG } from '../config';
    import ModelScore from './ModelScore.vue';
    import axios from 'axios'

    export default {
        name: "best-model",
        components: { ModelScore },
        data() {
            return {
                bench_list: [],
                valuemax: 0
            }
        },
        methods: {
            fetchBestModels() {
                axios.get(API_CONFIG.listModel).then((response) => {
                    this.bench_list = response.data.slice(0, 10);
                    this.valuemax = Math.max.apply(Math, this.bench_list.map(function(o) {return o.score;}))
                    for (var i = 0 ; i < this.bench_list.length ; i++) {
                        this.bench_list[i].percent = "width: " + (this.bench_list[i].score / this.valuemax * 100.0).toString() + "%";
                    }
                });
            }
        },
        mounted: function() {
            this.fetchBestModels()
        }
    }
</script>
