<template>
    <div class="d-flex align-items-center" >
        <div class="align-self-baseline text-padding">{{Number(score.toFixed(0))}}&nbsp;</div>
        <span class="align-self-baseline">
            <img src="../assets/icon_benchmark.png" height=14px width=14px/>
        </span>
    </div>
</template>

<script>
export default {
    name: "Score",
    props: ['score']
}
</script>
