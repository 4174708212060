<template>
    <div>
        <!-- <button
            v-b-modal.screenshots_modal_0_1
            class="btn btn-default"
        >
            Screenshots
        </button> -->
        <button
            v-b-modal="'{{this.modal_id}}'"
            @click="$bvModal.show(modal_id)"
            class="btn btn-default"
        >
            Screenshots
        </button>
        <b-modal
            :id="this.modal_id"
            title="Screenshots"
            size="xl"
            ok-only
        >
            <div
                class="row container vertical"
                v-for="(screen, index) in screenshots"
                :key="index"
            >
                <div class="center" v-if="screen.score != -1">
                    <img class="screenshots" v-bind:src="screen.full_screen_path"/>
                    <div class="top-padding horizontal flex-center">
                        <div class="vertical">
                            <h5>
                                {{screen.name}}:
                            </h5>
                            <div class="">
                                score:&nbsp;{{screen.score}}
                            </div>
                        </div>
                        <a :href="screen.full_screen_path" download>
                            <button class="btn btn-default" download>
                                <i class="fa fa-download"></i>
                                Download
                            </button>
                        </a>
                    </div>
                </div>
                <div class="page-header"></div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { BModal, VBModal } from 'bootstrap-vue'
export default {
    name: `ScreenshotsModal`,
    props: ['screenshots', 'index'],
    components: { BModal },
    directives: { 'b-modal': VBModal },
    computed: {
        modal_id: function() {
            return "screenshots_modal_" + this.index;
        }
    }
}
</script>