<template>
    <div>
        <h3 class="collapse-panel-title">Score</h3>
        <p></p>
        <p>Score: {{Number(benchmark.score.toFixed(0))}}</p>
        <p>Score software: {{Number(benchmark.score_software.toFixed(0))}}</p>
        <p>Score hardware: {{Number(benchmark.score_hardware.toFixed(0))}}</p>
    </div>
</template>

<script>
export default {
    name: 'bench-score',
    props: ['benchmark']
}
</script>
