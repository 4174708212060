<template>
    <div>
        <h3 class="collapse-panel-title">Model</h3>
        <p>Model: {{model_information.model}}</p>
        <p>Brand: {{model_information.brand}}</p>
        <p></p>
    </div>
</template>

<script>
export default {
    name: 'model-info',
    props: ['model_information']
}
</script>
