<template>
    <!-- news section -->
    <div v-if="news_list.length > 0">
        <div>
            <h2 class="page-header">News</h2>
            <div class="clearfix" v-for="(news, index) in news_list" :key="index">
                <news-article :news="news"></news-article>
            </div>
        </div>
    </div>
</template>

<script>
import { API_CONFIG, API_URL } from '../config';
import NewsArticle from './NewsArticle.vue'
import axios from 'axios'

export default {
    name: 'news',
    components: { NewsArticle },
    data() {
        return {
            news_list: [],
            test: 'test'
        }
    },
    methods: {
        fetchNews() {
            axios.get(API_CONFIG.news).then((response) => {
                this.news_list = response.data;
                for (var i = 0 ; i < this.news_list.length ; i++) {
                    this.news_list[i].date = this.news_list[i].date.substring(0, 10)
                    this.news_list[i].image_path = API_URL + this.news_list[i].image_path
                }
            });
        }
    },
    mounted: function() {
        this.fetchNews();
    }
}
</script>
