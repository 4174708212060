<template>
    <div class="row card">
        <div class="card-body horizontal">
            <img class="col-md-3 smooth-img" v-bind:src="news.image_path"/>
            <div class="col-md-9">
                <div>
                    <h5 class="card-heading card-title">{{ news.date }} - {{ news.title }}</h5>
                </div>
                <div class="card-text">
                    {{ news.content }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'news-article',
    props: ['news']
}
</script>
