<template>
    <div class="container" id="tests">
        <div v-if="loaded" class="row">
            <h1 class="col-md-12 page-header text-md-center">{{model_information.brand}} - {{model_information.model}}</h1>
            <b-tabs class="col-md-12">
                <b-tab title="All versions" active>
                    <div class="row">
                        <div class="col-md-2">
                            <div class="sticky">
                                <bench-score :benchmark="benchmark"></bench-score>
                                <model-info :model_information="model_information"></model-info>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <list-test-results :test_results="test_results" :tab_index='"model"'/>
                            <back-top-button></back-top-button>
                        </div>
                    </div>
                </b-tab>
                <b-tab
                    v-for="(submodel, tab_index) in submodels"
                    :title='submodel.sub_model_info.sub_model'
                    :key='submodel.sub_model_info.sub_model'>
                    <submodel-benchmark :submodel="submodel"
                     :tab_index="tab_index"/>
                </b-tab>
            </b-tabs>
        </div>
        <div v-else class="d-flex vertical center-page spinner" style="margin: 0 auto">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
import { getUrlParameterByName } from '../utils.js'
import { API_CONFIG } from '../config';
import { BTab, BTabs } from 'bootstrap-vue'
import ListTestResults from '../components/ListTestResults.vue'
import ModelInfo from '../components/ModelInfo.vue'
import BenchScore from '../components/BenchScore.vue'
import BackTopButton from '../components/BackTopButton.vue'
import SubmodelBenchmark from '../components/SubModelBenchmark.vue'
import Stickyfill from 'stickyfilljs'
import axios from 'axios'
export default {
    name: 'model-page',
    components: {
        ListTestResults,
        ModelInfo,
        BenchScore,
        BackTopButton,
        SubmodelBenchmark,
        BTabs,
        BTab
    },
    data() {
        return {
            loaded: false,
            test_results: [],
            model_information: {},
            benchmark: {
              score: 0,
              score_software: 0,
              score_hardware: 0
            },
            submodels: [],
        }
    },
    methods: {
        fillResults(resultDict, result, testType) {
            var sampleName = result.sample
            var dict
            if (!Object.prototype.hasOwnProperty.call(resultDict, sampleName)) {
                dict = {}
                dict.software = {}
                dict.hardware = {}
                dict.score_software = 0
                dict.score_hardware = 0
                dict.score = 0
                dict.max_score_software = 0
                dict.max_score_hardware = 0
                dict.max_score = 0
                dict.sample = sampleName
            } else {
                dict = resultDict[sampleName]
            }
            var decodingMode
            if (result.hardware) {
                decodingMode = "hardware"
            } else {
                decodingMode = "software"
            }

            delete result.sample
            dict[decodingMode][testType] = result
            if (result.hardware) {
                dict.score_hardware += result.score
                dict.max_score_hardware += result.max_score
            } else {
                dict.score_software += result.score
                dict.max_score_software += result.max_score
            }
            dict.score += result.score
            dict.max_score += result.max_score

            resultDict[sampleName] = dict
            return resultDict
        },
        fetchModelBenchmarkAvg() {
            var id = getUrlParameterByName("id");
            if (id != null) {
                axios.get(`${API_CONFIG.modelBenchmark}${id.toString()}`).then((response) => {
                    this.loaded = true;
                    var results = []
                    var qualityArray = response.data.model.results.quality;
                    for (var i = 0 ; i < qualityArray.length ; i++) {
                        results = this.fillResults(results, qualityArray[i], "quality")
                    }
                    var playbackArray = response.data.model.results.playback
                    // qualityArray = response.data.model.results.quality;
                    for (i = 0 ; i < playbackArray.length ; i++) {
                        results = this.fillResults(results, playbackArray[i], "playback")
                    }
                    var speedArray = response.data.model.results.speed;
                    for (i = 0 ; i < speedArray.length ; i++) {
                        results = this.fillResults(results, speedArray[i], "speed")
                    }
                    this.test_results = Object.values(results)
                    this.model_information = response.data.model.model_info;
                    this.model_information.brand = this.model_information.brand.toUpperCase();
                    this.benchmark.score = response.data.model.score;
                    this.benchmark.score_software = response.data.model.score_software;
                    this.benchmark.score_hardware = response.data.model.score_hardware;

                    for (i = 0 ; i < response.data.submodel.length ; i++) {
                        results = []
                        this.submodels = []
                        var sub = new Object();
                        var benchmark = new Object()
                        benchmark.score = response.data.submodel[i].score;
                        benchmark.score_software = response.data.submodel[i].score_software;
                        benchmark.score_hardware = response.data.submodel[i].score_hardware;
                        sub.benchmark = benchmark;
                        sub.sub_model_info = response.data.submodel[i].sub_model_info;
                        sub.model_info = response.data.submodel[i].sub_model_info.model_info;
                        sub.model_info.brand = sub.model_info.brand.toUpperCase();
                        playbackArray = response.data.submodel[i].results.playback
                        qualityArray = response.data.submodel[i].results.quality;
                        speedArray = response.data.submodel[i].results.speed;
                        for (i = 0 ; i < qualityArray.length ; i++) {
                            results = this.fillResults(results, qualityArray[i], "quality")
                        }
                        for (i = 0 ; i < playbackArray.length ; i++) {
                            results = this.fillResults(results, playbackArray[i], "playback")
                        }
                        for (i = 0 ; i < speedArray.length ; i++) {
                            results = this.fillResults(results, speedArray[i], "speed")
                        }
                        sub.test_results = Object.values(results)
                        this.submodels.push(sub);
                    }
                });
            } else {
                console.error("Failed to get id from URL");
            }
        }
    },
    mounted: function() {
        this.fetchModelBenchmarkAvg();
        var elements = document.querySelectorAll('.sticky');
        Stickyfill.add(elements);
    }
}
</script>
