<template>
    <!-- best bench -->
    <div id="bestbench">
        <h2 class="page-header">Best benchmarks</h2>
        <ul class="list-group card">
            <li v-for="(bench, index) in bench_list" :key="index" class="borderless-card  list-group-item row ">
                <div class="col-md-12 d-flex justify-content-between">
                    <router-link class="model-name" :to="{ name: 'Benchmark', query: { id: bench.benchmark_id} }">
                        {{ bench.brand }} - {{ bench.model }}
                    </router-link>
                    <Score :score="bench.score"/>
                </div>
                <div class="col-md-12">
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0"
                             aria-valuemax="200" :aria-valuenow="bench.score" :style="bench.percent">
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { API_CONFIG } from '../config';
import Score from './Score.vue'
import axios from 'axios'

export default {
    name: "best-bench",
    components: { Score },
    data() {
        return {
            bench_list: [],
            valuemax: 0
        }
    },
    methods: {
        fetchBestBench() {
            axios.get(API_CONFIG.best).then((response) => {
                this.bench_list = response.data;
                this.valuemax = Math.max.apply(Math, this.bench_list.map(function(o) {return o.score;}))
                for (var i = 0 ; i < this.bench_list.length ; i++) {
                    this.bench_list[i].percent = "width: " + (this.bench_list[i].score / this.valuemax * 100.0).toString() + "%";
                }
            });
        }
    },
    mounted: function() {
        this.fetchBestBench()
    }
}
</script>
