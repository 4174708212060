<template>
    <div class="container">
        <div class="row">
            <!-- avg bench -->
            <div class="col-md-12" id="benchavg">
                <h2 class="page-header">Model benchmark average</h2>
                <ul class="list-group card">
                    <li v-for="(bench, index) in bench_list" :key="index" class="list-group-item row borderless-card">
                        <ModelScore :bench="bench" :index="index"/>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ModelScore from '../components/ModelScore.vue'
import { API_CONFIG } from '../config';
import axios from 'axios'

export default {
    name: 'models-page',
    components: { ModelScore },
    data() {
        return {
            bench_list: [],
            valuemax: 0
        }
    },
    methods: {
        fetchModelList() {
            axios.get(API_CONFIG.listModel).then((response) => {
                this.bench_list = response.data;
                this.valuemax = Math.max.apply(Math, this.bench_list.map(function(o) {return o.score;}))
                for (var i = 0 ; i < this.bench_list.length ; i++) {
                    this.bench_list[i].percent = "width: " + (this.bench_list[i].score / this.valuemax * 100.0).toString() + "%";
                    for (var inc = 0 ; inc < this.bench_list[i].sub_model.length ; inc++) {
                        this.bench_list[i].sub_model[inc].percent = "width: " + (this.bench_list[i].sub_model[inc].score / this.valuemax * 100.0).toString() + "%";
                    }
                }
            });
        }
    },
    mounted: function() {
        this.fetchModelList();
    }
}
</script>
