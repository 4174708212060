<template>
    <div>
        <h3 class="collapse-panel-title">Test Results</h3>
        <div v-for="(test, index) in test_results" :key="index">
            <test-result :test="test" :row_index="index" :tab_index="tab_index"></test-result>
        </div>
    </div>
</template>

<script>
    import TestResult from './TestResult.vue'
    export default {
        name: 'list-test-results',
        props: ['test_results', 'tab_index'],
        components: {TestResult},

        created: function() {
            // console.log(this.test_results)
            this.test_results = this.test_results.sort((a, b) => {
                // Temporary fix for API inconsistency
                //TODO fix api
                var aHasName = Object.prototype.hasOwnProperty.call(a.sample, 'name')
                var aname = aHasName ? a.sample.name : a.sample
                var astr = aname.split("_")[0]
                var bHasName = Object.prototype.hasOwnProperty.call(b.sample, 'name')
                var bname = bHasName ? b.sample.name : b.sample
                var bstr = bname.split("_")[0]
                return Number(astr) - Number(bstr)
            })
            // console.log(this.test_results)
        }
    }
</script>
