<template>
    <div class="row">
        <div class="col-md-2">
            <div class="sticky">
                <bench-score :benchmark="submodel.benchmark"></bench-score>
                <model-info :model_information="submodel.model_info"></model-info>
            </div>
        </div>
        <div class="col-md-10">
            <sub-model-info :sub_model_info="submodel.sub_model_info"></sub-model-info>
            <list-test-results :test_results="submodel.test_results" :tab_index="tab_index"/>
            <back-top-button></back-top-button>
        </div>
    </div>
</template>

<script>
import BenchScore from './BenchScore.vue'
import ModelInfo from './ModelInfo.vue'
import ListTestResults from './ListTestResults.vue'
import SubModelInfo from './SubModelInfo.vue'
import BackTopButton from './BackTopButton.vue'
export default {
    name: 'submodel-benchmark',
    components: {
        BenchScore,
        ModelInfo,
        ListTestResults,
        SubModelInfo,
        BackTopButton
    },
    props: ['submodel', 'tab_index']
}
</script>
