<template>
    <div>
        <div class="collapse-panel-title horizontal clickable d-flex justify-content-between" v-on:click="onSubmodelClick">
            <h3 class="no-bottom-margin no-padding">Sub-Model</h3>
            <span>
                <i v-bind:id="'submodel_chevron'" class="fa fa-chevron-down panel-header"></i>
            </span>
        </div>
        <div v-bind:id="'submodel_content'" class="collapse">
            <p></p>
            <p>Board: {{sub_model_info.board}}</p>
            <p>Bootloader: {{sub_model_info.bootloader}}</p>
            <p>Manufacturer: {{sub_model_info.manufacturer}}</p>
            <p>Abi 32 bit: {{sub_model_info.abi_32_bit}}</p>
            <p>Abi_64_bit: {{sub_model_info.abi_64_bit}}</p>
            <p>CPU model: {{sub_model_info.cpu_model}}</p>
            <p>CPU cores: {{sub_model_info.cpu_cores}}</p>
            <p>CPU frequencies: {{sub_model_info.cpu_min_freq}} - {{sub_model_info.cpu_max_freq}}</p>
            <p>Ram: {{sub_model_info.total_ram}}</p>
            <p>GPU model: {{sub_model_info.gpu_model}}</p>
            <p>GPU vendor: {{sub_model_info.gpu_vendor}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'sub-model-info',
        props: ['sub_model_info'],
        methods: {
            onSubmodelClick() {
                var chevron = document.getElementById("submodel_chevron");
                var content = document.getElementById("submodel_content");
                if (content.classList.contains('collapse')) {
                    content.classList.remove('collapse');
                    chevron.classList.remove('fa-chevron-down');
                    chevron.classList.add('fa-chevron-up');
                } else {
                    content.classList.add('collapse');
                    chevron.classList.remove('fa-chevron-up');
                    chevron.classList.add('fa-chevron-down');
                }
            }
        }
    }
</script>
