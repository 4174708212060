<template>
    <nav class="navbar navbar-light navbar-expand-md">
        <div class="container">
            <div class="navbar-nav d-flex align-items-center">
                <router-link class="navbar-brand" :to="{ name: 'Home' }">
                    <span><img id="logo" src="../assets/icon_benchmark.png"></span>
                    VLC-Benchmark
                </router-link>
                <router-link :to="{ name: 'Models' }" class="nav-item nav-link" exact-active-class="active" role="presentation">
                    <a>Models</a>
                </router-link>
                <router-link id="user-bench-link" :to="{ name: 'UserBench' }" class="nav-item nav-link" exact-active-class="active" role="presentation">
                    <a>Benchmarks</a>
                </router-link>
            </div>
            <div class="navbar-form">
                <form class="form-group" v-on:submit="onClickSearch">
                    <div class="input-group">
                        <input
                            v-model="search"
                            type="text"
                            class="form-control"
                            placeholder="Search"
                            id="searchInput"
                        >
                        <div class="input-group-btn">
                            <button class="btn btn-default" type="submit">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="form-inline bg-list" role="search">
                <button id="btn_connection" class="btn btn-default">Connect</button>
                <img title="test" id="img-google"/>
            </div>
        </div>
    </nav>
</template>

<script>
import router from '../routes.js'
export default {
    name: 'app-header',
    data() {
        return {
            search: ""
        }
    },
    methods: {
        onClickSearch() {
            router.push({ path: '/search', query: { value: this.search } });
        },
        onClickDisconnect() {
            if (window.isConnected && window.location.pathname === '/list-bench.html') {
                router.push({name: 'Home'});
            }
        }
    }
}
</script>