<template>
    <div class="container">
        <div v-if="loaded" class="row">
            <div class="col-md-2">
                <div class="sticky">
                    <div>
                        <h3 class="collapse-panel-title">
                            <div>Benchmark</div>
                        </h3>
                        <p></p>
                        <p>Score: {{Number(benchmark.score).toFixed(0)}}</p>
                        <p>Score software: {{Number(benchmark.score_software).toFixed(0)}}</p>
                        <p>Score hardware: {{Number(benchmark.score_hardware).toFixed(0)}}</p>
                        <p>VLC version: {{benchmark.vlc_version}}</p>
                    </div>
                    <model-info :model_information="model_info"/>
                </div>
            </div>
            <div class="col-md-9">
                <sub-model-info :sub_model_info="sub_model_info"></sub-model-info>
                <device-info :device_info="device_info"></device-info>
                <list-test-results :test_results="test_results" :tab_index="0"/>
                <back-top-button></back-top-button>
            </div>
        </div>
        <div v-else class="d-flex vertical center-page spinner" style="margin: 0 auto">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
import { getUrlParameterByName } from '../utils.js'
import { API_CONFIG } from '../config';
import { BSpinner } from 'bootstrap-vue'
import SubModelInfo from '../components/SubModelInfo.vue'
import ModelInfo from '../components/ModelInfo.vue'
import DeviceInfo from '../components/DeviceInfo.vue'
import ListTestResults from '../components/ListTestResults.vue'
import BackTopButton from '../components/BackTopButton.vue'
import Stickyfill from 'stickyfilljs'
import axios from 'axios'
export default {
    name: 'benchmark-page',
    components: {
        SubModelInfo,
        ListTestResults,
        BackTopButton,
        DeviceInfo,
        ModelInfo,
        BSpinner
    },
    data() {
        return {
            loaded: false,
            test_results: [],
            device_info: {},
            sub_model_info: {},
            model_info: {},
            benchmark: {}
        }
    },
    methods: {
        fillResults(resultDict, result, testType) {
            var sampleName = result.sample.name
            var dict
            if (!Object.prototype.hasOwnProperty.call(resultDict, sampleName)) {
                dict = {}
                dict.software = {}
                dict.hardware = {}
                dict.score_software = 0
                dict.score_hardware = 0
                dict.score = 0
                dict.max_score_software = 0
                dict.max_score_hardware = 0
                dict.max_score = 0
                dict.sample = sampleName
            } else {
                dict = resultDict[sampleName]
            }
            var decodingMode
            if (result.hardware) {
                decodingMode = "hardware"
            } else {
                decodingMode = "software"
            }

            delete result.sample
            dict[decodingMode][testType] = result
            if (result.hardware) {
                dict.score_hardware += result.score
                dict.max_score_hardware += result.max_score
            } else {
                dict.score_software += result.score
                dict.max_score_software += result.max_score
            }
            dict.score += result.score
            dict.max_score += result.max_score

            resultDict[sampleName] = dict
            return resultDict
        },
        fetchBenchmark() {
            var id = getUrlParameterByName("id");
            if (id != null) {
                axios.get(`${API_CONFIG.benchmarks}${id.toString()}`).then((response) => {
                    if (response.data.length >= 1) {
                        console.log(response.data[0]) 
                        var results = {}
                        this.loaded = true;
                        // this.test_results = response.data[0].test.sort(compare);
                        var qualityArray = response.data[0].results.quality
                        for (var i = 0 ; i < qualityArray.length ; i++) {
                            results = this.fillResults(results, qualityArray[i], "quality")
                        }
                        var playbackArray = response.data[0].results.playback
                        for (i = 0 ; i < playbackArray.length ; i++) {
                            results = this.fillResults(results, playbackArray[i], "playback")
                        }
                        var speedArray = response.data[0].results.speed
                        for (i = 0 ; i < speedArray.length ; i++) {
                            results = this.fillResults(results, speedArray[i], "speed")
                        }
                        this.test_results = Object.values(results)
                        console.log(this.test_results)
                        this.device_info = response.data[0].device_info;
                        this.sub_model_info = this.device_info.sub_model_info;
                        this.model_info = this.sub_model_info.model_info;
                        this.benchmark.score = Number(response.data[0].score.toFixed(2));
                        this.benchmark.score_software = Number(response.data[0].score_software.toFixed(2));
                        this.benchmark.score_hardware = Number(response.data[0].score_hardware.toFixed(2));
                        this.benchmark.vlc_version = response.data[0].vlc_version;
                    }
                });
            }
        }
    },
    mounted: function() {
        this.fetchBenchmark();
        var elements = document.querySelectorAll('.sticky');
        Stickyfill.add(elements);
    }
}
</script>
