<template>
    <div id="app">
        <app-header></app-header>
        <div class="main-view bottom-footer-space">
            <router-view></router-view>
        </div>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import AppFooter from './components/AppFooter.vue'
    import AppHeader from './components/AppHeader.vue'
    export default {
        name: 'app',
        components: { AppFooter, AppHeader },
        data() {
            return {}
        }
    }
</script>
