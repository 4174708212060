<template>
    <div class="container">
        <div v-if="loaded" class="row">
            <!-- search results -->
            <div v-if="empty" class="center-page">
                <img class="center bottom-padding" src="../assets/icon_benchmark.png"/>
                <h2>Sorry there was no results.</h2>
            </div>
            <div v-else class="col-md-12" id="results">
                <h2 class="page-header">Search results</h2>
                <ul class="list-group card">
                    <li v-for="result in sub_model" class="list-group-item row">
                        <div class="col-md-12">
                            <span>
                                <router-link class="model-name" :to="{ name: 'Model', query: { id: result.model_id } }">
                                    {{ result.model }} - {{ result.sub_model }}
                                </router-link>
                            </span>
                            <span class="model-value">{{Number(result.score).toFixed(0)}}</span>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" aria-valuemin="0"
                                     aria-valuemax="200" :aria-valuenow="result.score" :style="result.percent">
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div v-else class="center-page spinner">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
import { getUrlParameterByName } from '../utils.js'
import { API_CONFIG } from '../config';
import { BSpinner } from 'bootstrap-vue'
import axios from 'axios'

export default {
    name: 'search-page',
    components: { BSpinner },
    data() {
        return {
            loaded: false,
            empty: false,
            sub_model: []
        }
    },
    watch: {
        '$route.query.value'() {
            console.log("query value change");
            this.fetchSearchResults();
        }
    },
    methods: {
        fetchSearchResults() {
            var value = getUrlParameterByName("value");
            axios.post(API_CONFIG.search, {value: value}).then((response) => {
                this.loaded = true;
                if (response.data.length >= 1) {

                    this.sub_model = response.data;
                    for (var i = 0 ; i < this.sub_model.length ; i++) {
                        this.sub_model[i].percent = "width: " + (this.sub_model[i].score / 2.0).toString() + "%"
                    }
                } else {
                    this.empty = true;
                }
            });
        }
    },
    mounted: function() {
        this.fetchSearchResults();
    },
    beforeDestroy: function() {
        document.getElementById("searchInput").value="";
    }
}
</script>
