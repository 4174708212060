import Vue from 'vue'
import { BootstrapVue, ModalPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './routes.js'
import './css/main.scss'

window.isConnected = false;
new Vue({
  el: '#app',
  render: h => h(App),
  router: router
});
Vue.use(BootstrapVue);
Vue.use(ModalPlugin);
