<template>
    <div class="container" id="user_bench">
        <div class="row">
            <div class="col-md-12">
                <h2 class="page-header">Personnal benchmarks </h2>
                <ul class="list-group card">
                    <li class="list-group-item row borderless-card" v-for="(bench, index) in bench_list" :key="index">
                        <div class="col-md-12 d-flex justify-content-between">
                            <div>
                                <router-link
                                    class="model-name"
                                    :to="{
                                        name: 'Benchmark',
                                        query: { id: bench.bench_id }
                                    }"
                                >
                                    {{ bench.dateStr }}
                                </router-link>
                                -
                                <router-link
                                    class="model-name"
                                    :to="{
                                        name: 'Model',
                                        query: { id: bench.model_id }
                                    }"
                                >
                                    {{ bench.brand }} - {{ bench.model }} - {{ bench.sub_model}}
                                </router-link>
                            </div>
                            <Score :score="bench.score"/>
                        </div>
                        <div class="col-md-12">
                            <div class="progress">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    aria-valuemin="0"
                                    aria-valuemax="200"
                                    :aria-valuenow="bench.score"
                                    :style="bench.percent">
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { API_CONFIG } from '../config';
import Score  from '../components/Score.vue'
import axios from 'axios'

export default {
    name: 'user-bench-page',
    components: { Score },
    data() {
        return {
            bench_list: [],
            valuemax: 0
        }
    },
    methods: {
        fetchUserBench() {
            if (window.isConnected) {
                axios.post(API_CONFIG.listBench, { email: window.userEmail }).then((response) => {
                    if (response.data.length >= 1) {
                        this.bench_list = response.data;
                        this.valuemax = Math.max.apply(Math, this.bench_list.map(function(o) {return o.score;}))
                        for (var i = 0; i < this.bench_list.length; i++) {
                            this.bench_list[i].percent = "width: " + (this.bench_list[i].score / this.valuemax * 100).toString() + "%";
                            let date = new Date(this.bench_list[i].date);
                            this.bench_list[i].dateStr =
                                date.toLocaleString()
                        }
                    }
                });
            }
        }
    },
    mounted: function() {
        this.fetchUserBench();
    }
}
</script>
