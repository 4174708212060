<template>
    <div class="d-flex justify-content-between">
        <dt v-if="bold">{{this.text}}</dt>
        <dd v-else class="sub-padding">{{this.text}}</dd>
        <div>
            <span>
                <i v-bind:id="this.id" class="fa fa-question-circle"></i>
            </span>
            <b-popover
                :placement="'top'"
                triggers="hover focus"
                :target="this.id"
            >
                {{this.help}}
            </b-popover>
        </div>
    </div>
</template>

<script>
  export default {
    name: "DecodingScoreDetail",
    props: ['id', 'text', 'help', 'bold']
  }
</script>