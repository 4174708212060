export const API_URL = process.env.NODE_ENV === 'production' ? 'https://bench.videolabs.io/api/' : 'http://localhost:8080/';
export const API_CONFIG = {
    benchmarks: `${API_URL}benchmarks?id=`,
    best: `${API_URL}best`,
    listModel: `${API_URL}list-model`,
    listBench: `${API_URL}list-bench`,
    news: `${API_URL}news`,
    model: `${API_URL}model?id=`,
    submodel: `${API_URL}submodel?id=`,
    search: `${API_URL}search`,
    recommendation: `${API_URL}recommendation`,
    modelBenchmark: `${API_URL}model-bench?id=`
}
