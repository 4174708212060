<template>
    <footer class="footer-style navbar navbar-light vertical">
            <a class="center" href="https://play.google.com/apps/testing/org.videolan.vlcbenchmark">
                <img
                    src="../assets/google-play-badge.png"
                    height="75px"
                    class="center"
                >
            </a>
            <div class="text-md-center">
                <a href="https://videolabs.io">
                    Videolabs
                </a>
                2012 - 2019. All rights reserved.
            </div>
    </footer>
</template>

<script>
    export default {
        name: 'app-footer',
        data () {
            return {}
        }
    }
</script>
