<template>
    <div v-if="news != null">
        <h2 class="page-header">Device recommendation from the team</h2>
        <div class="horizontal">
            <img class="col-md-3 smooth-img" v-bind:src="news.image_path"/>
            <div class="col-md-9">
                <div>
                    <h5 class="card-heading card-title">{{ news.title }}</h5>
                </div>
                <div class="card-text">
                    {{ news.content }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_CONFIG, API_URL } from '../config'
import NewsArticle from './NewsArticle.vue'
import axios from 'axios'
export default {
    name: 'device-recommendation',
    components: { NewsArticle },
    data() {
        return {
            news: {}
        }
    },
    methods: {
        fetchRecommendation() {
            axios.get(API_CONFIG.recommendation)
                .then((response) => {
                    if (response.data == "") {
                        this.news = null
                    } else {
                        this.news = response.data;
                        this.news.image_path = API_URL + this.news.image_path
                    }
            })
        }
    },
    mounted: function() {
        this.fetchRecommendation();
    }
}
</script>
