<template>
    <dl>
        <div v-if="Object.prototype.hasOwnProperty.call(this.results, 'playback')">
            <!-- Playback values -->
            <DecodingScoreDetail
                :id="playback_score_id"
                :text="`Playback score: ${Number(this.results.playback.score.toFixed(0))} / ${Number(this.results.playback.max_score)}`"
                :help="playback_score_help"
                :bold="true">
            </DecodingScoreDetail>
            <DecodingScoreDetail
                :id="frames_dropped_id"
                :text="`Frames dropped: ${Number(results.playback.frames_dropped.toFixed(0))}`"
                :help="frames_dropped_help"
                :bold="false">
            </DecodingScoreDetail>
            <DecodingScoreDetail
                :id="warnings_id"
                :text="`Warnings: ${Number(results.playback.warnings.toFixed(0))}`"
                :help="warnings_help"
                :bold="false">
            </DecodingScoreDetail>
            <DecodingScoreDetail
                    :id="crash_playback_id"
                    :text="`Crash: ${results.playback.crash}`"
                    :help="crash_help"
                    :bold="false"
                    v-if="results.playback.crash !== '' && results.playback.crash !== undefined">
            </DecodingScoreDetail>
        </div>

        <div v-if="Object.prototype.hasOwnProperty.call(this.results, 'quality')">
            <div 
                style="padding-bottom: 10px"
                v-if="Object.prototype.hasOwnProperty.call(this.results, 'playback')"
            >
            </div>
            <!-- Quality Values -->
            <DecodingScoreDetail
                :id="quality_score_id"
                :text="`Quality score: ${Number(results.quality.score.toFixed(0))} / ${Number(results.quality.max_score)}`"
                :help="quality_score_help"
                :bold="true">
            </DecodingScoreDetail>
            <DecodingScoreDetail
                :id="bad_screenshots_id"
                :text="`Bad screenshots: ${Number(results.quality.bad_screenshot_percent.toFixed(0))} %`"
                :help="bad_screenshots_help"
                :bold="false">
            </DecodingScoreDetail>
            <DecodingScoreDetail
                :id="crash_quality_id"
                :text="`Crash: ${results.quality.crash}`"
                :help="crash_help"
                :bold="false"
                v-if="results.quality.crash !== '' && results.quality.crash !== undefined">
            </DecodingScoreDetail>
            <!-- <div style="padding-bottom: 10px"></div> -->
        </div>

        <div v-if="Object.prototype.hasOwnProperty.call(this.results, 'speed')">
            <div 
                style="padding-bottom: 10px"
                v-if="Object.prototype.hasOwnProperty.call(this.results, 'playback') || Object.prototype.hasOwnProperty.call(this.results, 'quality')"
            ></div>
            <!-- Speed Values -->
            <DecodingScoreDetail
                :id="speed_score_id"
                :text="`Speed score: ${Number(results.speed.score.toFixed(0))} / ${Number(results.speed.max_score)}`"
                :help="speed_score_help"
                :bold="true"
            >
            </DecodingScoreDetail>
            <DecodingScoreDetail
                :id="speed_speed_id"
                :text="`Speed: ${Number(results.speed.speed.toFixed(2))}`"
                :help="speed_speed_help"
                :bold="false"
            >
            </DecodingScoreDetail>
            <DecodingScoreDetail
                :id="crash_speed_id"
                :text="`Crash: ${results.speed.crash}`"
                :help="crash_help"
                :bold="false"
                v-if="results.speed.crash !== '' && results.speed.crash !== undefined">
            </DecodingScoreDetail>
        </div>
    </dl>
</template>

<script>
    import DecodingScoreDetail from './DecodingScoreDetail.vue'
  export default {
    name: "DecodingScore",
    components: { DecodingScoreDetail },
    props: ['results', 'row_index', 'tab_index', 'decoding_type'],
    computed: {
      index: function() {
        return this.tab_index.toString() + "_" + this.row_index.toString() + "_" + this.decoding_type;
      },
      playback_score_id: function() {
        return "playback_score_" + this.index;
      },
      frames_dropped_id: function() {
        return "frames_dropped_" + this.index;
      },
      warnings_id: function() {
        return "warnings_" + this.index;
      },
      quality_score_id: function() {
        return "quality_score_" + this.index;
      },
      bad_screenshots_id: function() {
        return "bad_screenshots_" + this.index;
      },
      crash_quality_id: function() {
        return "crash_quality_" + this.index;
      },
      crash_playback_id: function() {
        return "crash_playback_" + this.index;
      },
      speed_score_id: function() {
        return "speed_score_" + this.index;
      },
      speed_speed_id: function() {
          return "speed_speed_" + this.index;
      }
    },
    data() {
      return {
        playback_score_help: 'Playback score is based on errors and warnings received during sample playback. The more there are, the lower the score.',
        frames_dropped_help: 'Frames dropped means the player struggles to decode the video.',
        warnings_help: 'Warnings are signs of lower struggle than frame drops, hence they also have a lower impact on the score.',
        quality_score_help: 'Quality score is based on screenshots taken during the test and compared to our references.',
        bad_screenshots_help: 'This is the percentage of screenshots that failed the verification comparison.',
        crash_help: 'These are the causes of crashes during tests.',
        speed_score_help: 'Speed score is base on maximum playback speed during the test. If it is below 1, the score will go down',
        speed_speed_help: 'This is the maximum playback speed reached during the test.'
      }
    },
  }
</script>