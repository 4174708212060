<template>
    <div>
        <div class="container">
            <div class="row">
                <best-model class="col-md-6"/>
                <best-bench class="col-md-6"/>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <device-recommendation class="col-md-12"/>
            </div>
        </div>
        <div>
            <div class="container">
                <news class="col-md-12"/>
            </div>
        </div>
    </div>
</template>

<script>
import News from '../components/News.vue'
import BestModel from '../components/BestModels.vue'
import BestBench from '../components/BestBench.vue'
import DeviceRecommendation from '../components/DeviceRecommendation.vue'
export default {
    name: 'home-page',
    components: { News, BestModel, BestBench, DeviceRecommendation },
    data() {
        return {}
    }
}
</script>
