<template>
    <div class="">
        <div class="collapse-panel-title horizontal clickable d-flex justify-content-between" v-on:click="onDeviceClick">
            <h3 class="no-bottom-margin no-padding">Device information</h3>
            <span>
                <i v-bind:id="'device_chevron'" class="fa fa-chevron-down panel-header"></i>
            </span>
        </div>
        <div v-bind:id="'device_content'" class="collapse">
            <p>Android version: {{device_info.version}} (API {{device_info.sdk}})</p>
            <p>Display: {{device_info.display}}</p>
            <p>Product: {{device_info.product}}</p>
            <p>OpenGL version: {{device_info.opengl_version}}</p>
            <p>OpenGL extensions: {{device_info.opengl_extensions}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'device-info',
        props: ['device_info'],
        methods: {
            onDeviceClick() {
                var chevron = document.getElementById("device_chevron");
                var content = document.getElementById("device_content");
                if (content.classList.contains('collapse')) {
                    content.classList.remove('collapse');
                    chevron.classList.remove('fa-chevron-down');
                    chevron.classList.add('fa-chevron-up');
                } else {
                    content.classList.add('collapse');
                    chevron.classList.remove('fa-chevron-up');
                    chevron.classList.add('fa-chevron-down');
                }
            }
        }
    }
</script>
