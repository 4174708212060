<template>
    <div
        v-bind:id="this.panel_id"
        class="card clickable"
        v-on:click="onPanelClick"
        @mouseover="onPanelMouseOver"
        v-if="test.sample != ''"
    >
        <div v-bind:id="this.title_id">
            <div class="row">
                <div class="col-md-10">
                    <div class="horizontal">
                        <p class="no-bottom-margin">{{test.sample}}</p>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0"
                             aria-valuemax="100" :aria-valuenow="test.percent" :style="test.progress"
                             v-bind:id="this.progress_id">
                        </div>
                    </div>
                </div>
                <div class="col-md-2 d-flex justify-content-end">
                    <span class="model-value no-padding align-self-center align-middle">
                        <h4 class="no-bottom-margin text-md-center align-middle">{{this.score_str}}</h4>
                    </span>
                    <span class="pull-right">
                        <i v-bind:id="this.chevron_id" class="fa fa-chevron-down panel-header"></i>
                    </span>
                </div>

            </div>
        </div>
        <div v-bind:id="this.card_id" class="card-body vertical collapse flex-container">
            <div class="row">
                <div 
                    class="col-md-6"
                    v-if="Object.keys(test.software).length > 0"
                >
                    <h6 class="card-heading panel-title">
                        Software decoding: {{Number(test.score_software.toFixed(0))}} / {{Number(test.max_score_software.toFixed(0))}}
                    </h6>
                    <DecodingScore
                            :results="test.software"
                            :row_index="row_index"
                            :tab_index="tab_index"
                            :decoding_type="'software'">
                    </DecodingScore>
                </div>
                <div
                    class="col-md-6"
                    v-if="Object.keys(test.hardware).length > 0"
                >
                    <h6 class="card-heading panel-title">
                        Hardware decoding: {{Number(test.score_hardware.toFixed(0))}} / {{Number(test.max_score_hardware.toFixed(0))}}
                    </h6>
                    <dl>
                        <DecodingScore
                            :results="test.hardware"
                            :row_index="row_index"
                            :tab_index="tab_index"
                            :decoding_type="'hardware'">
                        </DecodingScore>
                    </dl>
                </div>
            </div>
            <div
                class="row center"
                @mouseover="onButtonMouseOver"
            >
                <ScreenshotsModal
                    @mouseover="onButtonMouseOver"
                    :screenshots="test.screenshots"
                    :index="index"
                    class="col-md-12 center-btn"
                    v-if="hasScreenshots"
                >
                </ScreenshotsModal>
            </div>
        </div>
    </div>
</template>

<script>
import DecodingScore from './DecodingScore.vue'
import ScreenshotsModal from './ScreenshotsModal.vue'
export default {
    name: 'test-result',
    components: { DecodingScore, ScreenshotsModal },
    props: ['test', 'row_index', 'tab_index'],
    computed: {
        index: function() {
            return this.tab_index.toString() + "_" + this.row_index.toString();
        },
        card_id: function() {
            return "card_" + this.index;
        },
        chevron_id: function() {
            return "chevron_" + this.index;
        },
        title_id: function() {
            return "title_" + this.index;
        },
        progress_id: function() {
            return "progress_" + this.index;
        },
        panel_id: function() {
            return "panel_" + this.index;
        },
        hasScreenshots: function() {
            return this.screenshotsCheck();
        }
    },
    data() {
        return {
            score_str: '',
            hoverButton: false,
            panelDisabled: false
        }
    },
    mounted: function() {
        // Temporary fix for API inconsistency
        //TODO fix api
        if (this.test.sample.hasOwnProperty('name')) {
            this.test.sample = this.test.sample.name;
        }
        if (this.test.max_score != 0) {
            this.test.percent = this.test.score / this.test.max_score * 100
            this.test.progress = "width: " + this.test.percent + "%;";
            let progress = document.getElementById(this.progress_id);
            if (this.test.percent > 75) {
                progress.classList.add('result-color-good');
            } else if (this.test.percent > 40) {
                progress.classList.add('result-color-medium');
            } else {
                progress.classList.add('result-color-bad');
            }
            progress.style = this.test.progress;
            this.score_str = Number(this.test.percent).toFixed(0).toString() +  "%"
        }
    },
    methods: {
        onPanelClick() {
            if (this.panelDisabled === false) {
                let card = document.getElementById(this.card_id);
                let chevron = document.getElementById(this.chevron_id);
                let title = document.getElementById(this.title_id);
                if (card.classList.contains('collapse')) {
                    card.classList.remove('collapse')
                    chevron.classList.remove('fa-chevron-down')
                    chevron.classList.add('fa-chevron-up')
                    title.classList.add('card-heading')
                } else {
                    card.classList.add('collapse')
                    chevron.classList.remove('fa-chevron-up')
                    chevron.classList.add('fa-chevron-down')
                    title.classList.remove('card-heading')
                }
            }
        },
        onButtonMouseOver() {
            let panel = document.getElementById(this.panel_id);
            if (panel.classList.contains('clickable')) {
                panel.classList.remove('clickable')
                this.hoverButton = true
                this.panelDisabled = true
            }
        },
        onPanelMouseOver() {
            let panel = document.getElementById(this.panel_id);
            if (!panel.classList.contains('clickable') && this.hoverButton === false) {
                panel.classList.add('clickable')
                this.panelDisabled = false
            } else if (this.hoverButton === true) {
                this.hoverButton = false
            }
        },
        screenshotsCheck() {
            if (!this.test.hasOwnProperty("screenshots")) {
                return false
            }
            for (var i = 0 ; i < this.test.screenshots.length ; i++) {
                if (this.test.screenshots[i].score !== -1) {
                    return true
                }
            }
            return false
        }
    }
}
</script>
