<template>
    <a @click="backToTop" id="back-top-button"><i class="fa fa-chevron-up"></i></a>
</template>

<script>
export default {
    name: 'back-top-button',
    methods: {
        checkScrollPosition() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
              document.getElementById("back-top-button").style.display = "block";
            } else {
              document.getElementById("back-top-button").style.display = "none";
            }
        },
        backToTop() {
            window.scrollTo(0, 0)
        }
    },
    created() {
        window.addEventListener('scroll', this.checkScrollPosition);
    },
    destroyed() {
        window.removeEventListener('scroll', this.checkScrollPosition);
    }
}
</script>
