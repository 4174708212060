<template>
    <div>
        <div class="row">
            <div class="col-md-12 d-flex justify-content-between">
                <div>
                    <span v-if="typeof bench.ranking !== 'undefined'">
                        #{{bench.ranking}}&nbsp;
                    </span>
                    <router-link
                            class="model-name"
                            :to="{ name: 'Model',
                            query: { id: bench.model_id} }">
                        {{ bench.brand }} - {{ bench.model }}
                    </router-link>
                </div>
                <Score :score="bench.score"/>
            </div>
            <div class="col-md-12">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuemin="0"
                         aria-valuemax="200" :aria-valuenow="bench.score" :style="bench.percent">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Score from './Score.vue'

export default {
    name: "ModelScore",
    components: { Score },
    props: ['bench', 'index']
}
</script>
